import { MODAL_TYPE } from '../types'
import { CloseOnlyHeader } from './CloseOnlyHeader/CloseOnlyHeader'
import { CompleteHeader } from './CompleteHeader/CompleteHeader'
import {
	defaultModalHeadersProps,
	specialModalHeaderProps,
} from './ModalHeadersProps'

export const ModalHeader = ({
	title,
	subtitle,
	preventClose = false,
	showTitle = false,
	close,
	type,
	className,
}: {
	title: string
	type: MODAL_TYPE
	showTitle?: boolean
	subtitle?: string
	preventClose?: boolean
	close: () => void
	className: string
}) => {
	const {
		showHeaderSeparator,
		closeOnly,
		hasCenteredTitle,
		showBar,
		showClose,
		floatClose,
	} =
		specialModalHeaderProps[type as MODAL_TYPE.SHEET_FULL_SCREEN] ??
		defaultModalHeadersProps

	const hasSheetTitle = [
		MODAL_TYPE.SHEET,
		MODAL_TYPE.SHEET_FULL_SCREEN,
		MODAL_TYPE.SHEET_INSPIRATIONAL,
		MODAL_TYPE.SHEET_SMALL,
	].includes(type)

	return (
		<>
			{closeOnly ? (
				<CloseOnlyHeader
					close={close}
					floatClose={floatClose}
					title={title}
					preventClose={preventClose}
					className={className}
				/>
			) : (
				<CompleteHeader
					className={className}
					close={close}
					title={title}
					showBar={showBar}
					hasSheetTitle={hasSheetTitle}
					showHeaderSeparator={showHeaderSeparator}
					showTitle={showTitle}
					showClose={showClose}
					subtitle={subtitle}
					preventClose={preventClose}
					hasCenteredTitle={hasCenteredTitle}
				/>
			)}
		</>
	)
}
