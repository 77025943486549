export interface ModalAPI {
	show: () => void
	close: () => void
	isOpen: boolean
}

export enum MODAL_TYPE {
	'S' = 'S',
	'M' = 'M',
	'L' = 'L',
	'XL' = 'XL',
	'FULL_SCREEN' = 'FULL_SCREEN',
	'SIDE' = 'SIDE',
	'SIDE_FULL_CONTENT' = 'SIDE_FULL_CONTENT',
	/** Shows a MODAL_TYPE.SIDE on breakpoint > S */
	'SHEET' = 'SHEET',
	/** Animation from the bottom and with close button */
	'SHEET_FULL_SCREEN' = 'SHEET_FULL_SCREEN',
	/** Shows a MODAL_TYPE.S on breakpoint > S */
	'SHEET_SMALL' = 'SHEET_SMALL',
	/** Like MODAL_TYPE.SHEET but without heading line */
	'SHEET_INSPIRATIONAL' = 'SHEET_INSPIRATIONAL',
}

/**
 * The optional `role` attribute of the dialog element, either `dialog`
 * (default) or `alertdialog` to make it a modal (preventing closing on
 * click outside of ESC key).
 */
export enum DIALOG_TYPE {
	ALERT = 'alertdialog',
	DIALOG = 'dialog',
}

export interface ModalProps {
	ref: React.Ref<ModalAPI>
	/** (default: 'modal')  */
	'data-testid'?: string
	/** children MUST have an <h1> element inside for a11y purpose */
	children: React.ReactNode
	className?: string
	/** modal identifier to use as a reference for a11y purpose (default: 'modal') */
	name: string
	/** Title descriptor for a11y purpose */
	title: string
	/** Optional subtitle on sheet full screen */
	subtitle?: string
	footer?: React.ReactNode
	/** The modal handles for itself the close, use onClose if you need extra actions once is closed */
	onClose?: (event?: { [key: string]: string }) => unknown
	/** prevents the modal to be close clicking outside it (default: false) */
	preventClose?: boolean
	/** (default: MODAL_TYPE.L) */
	type: MODAL_TYPE
	/** Shows the title as a h1 in the header */
	showTitle?: boolean
	/** Flag to keep the content of the modal in the DOM even if the modal closes */
	keepContent?: boolean
}
