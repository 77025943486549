import { MODAL_TYPE } from '../types'

export interface ModalHeadersProps {
	showHeaderSeparator: boolean
	closeOnly: boolean
	hasCenteredTitle: boolean
	showBar: boolean
	showClose: boolean
	floatClose: boolean
}

export type HeaderTypes =
	| MODAL_TYPE.SHEET_FULL_SCREEN
	| MODAL_TYPE.SHEET
	| MODAL_TYPE.SHEET_INSPIRATIONAL
	| MODAL_TYPE.SHEET_SMALL
	| MODAL_TYPE.SIDE
	| MODAL_TYPE.SIDE_FULL_CONTENT
	| MODAL_TYPE.FULL_SCREEN

export const defaultModalHeadersProps: ModalHeadersProps = {
	showHeaderSeparator: false,
	closeOnly: true,
	hasCenteredTitle: false,
	showBar: false,
	showClose: false,
	floatClose: false,
}

export const specialModalHeaderProps: Record<HeaderTypes, ModalHeadersProps> = {
	[MODAL_TYPE.SIDE]: {
		closeOnly: false,
		showHeaderSeparator: false,
		hasCenteredTitle: false,
		showBar: false,
		showClose: true,
		floatClose: false,
	},
	[MODAL_TYPE.SHEET_FULL_SCREEN]: {
		closeOnly: false,
		showHeaderSeparator: true,
		hasCenteredTitle: true,
		showBar: false,
		showClose: true,
		floatClose: false,
	},
	[MODAL_TYPE.SIDE_FULL_CONTENT]: {
		closeOnly: true,
		showHeaderSeparator: false,
		hasCenteredTitle: false,
		showBar: false,
		showClose: false,
		floatClose: true,
	},
	[MODAL_TYPE.SHEET]: {
		closeOnly: false,
		showHeaderSeparator: true,
		hasCenteredTitle: true,
		showBar: true,
		showClose: false,
		floatClose: false,
	},
	[MODAL_TYPE.SHEET_SMALL]: {
		closeOnly: false,
		showHeaderSeparator: false,
		hasCenteredTitle: true,
		showBar: true,
		showClose: false,
		floatClose: false,
	},
	[MODAL_TYPE.SHEET_INSPIRATIONAL]: {
		closeOnly: false,
		showHeaderSeparator: true,
		hasCenteredTitle: true,
		showBar: true,
		showClose: false,
		floatClose: false,
	},
	[MODAL_TYPE.FULL_SCREEN]: {
		closeOnly: true,
		showHeaderSeparator: false,
		hasCenteredTitle: false,
		showBar: false,
		showClose: true,
		floatClose: true,
	},
}
