import { useEffect, useState } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { MODAL_TYPE } from '../types'

// Adapts the proper modal size depending on the screen size.
export function useModalSize(type: MODAL_TYPE): MODAL_TYPE {
	const [modalType, setModalType] = useState<MODAL_TYPE>(type)
	const { isSmall, isLargeOrGreater } = useResponsive()

	useEffect(() => {
		if (
			!isSmall &&
			[
				MODAL_TYPE.SHEET,
				MODAL_TYPE.SHEET_INSPIRATIONAL,
				MODAL_TYPE.SHEET_FULL_SCREEN,
			].includes(type)
		) {
			setModalType(MODAL_TYPE.SIDE)
		} else if (isLargeOrGreater && type === MODAL_TYPE.SHEET_SMALL) {
			setModalType(MODAL_TYPE.S)
		} else {
			setModalType(type)
		}
	}, [type, isSmall, isLargeOrGreater])

	return modalType
}
