import { type MutableRefObject, useEffect, useRef } from 'react'

const isClickOutsideDialog = (
	e: MouseEvent,
	dialogDimensions: DOMRect | undefined
) => {
	if (!dialogDimensions) {
		return false
	}

	return (
		e.clientX < dialogDimensions.left ||
		e.clientX > dialogDimensions.right ||
		e.clientY < dialogDimensions.top ||
		e.clientY > dialogDimensions.bottom
	)
}

const isPointerInteraction = (e: MouseEvent): boolean =>
	e.clientX + e.clientY !== 0

export const useModalListeners = ({
	dialogRef,
	preventClose,
	close,
}: {
	dialogRef: MutableRefObject<HTMLDialogElement | null>
	preventClose: boolean
	close: () => void
}): boolean => {
	const initializedEvents: MutableRefObject<boolean> = useRef(false)

	useEffect(() => {
		if (dialogRef.current && !initializedEvents.current) {
			initializedEvents.current = true

			// Listener to close the modal when clicking outside of it.
			// When onKeyPress is triggered inside a Modal, another click event is executed on the (0,0) coordinate of the DOM.
			// We need to differentiate this (sided) click event from the proper one done by the user with an actual pointer.
			dialogRef.current.addEventListener('click', (e) => {
				if (preventClose || !isPointerInteraction(e)) {
					return
				}

				const dialogDimensions = dialogRef.current?.getBoundingClientRect()

				if (!isClickOutsideDialog(e, dialogDimensions)) {
					return
				}

				close()
			})

			// Listener to close the modal when pressing the ESC key.
			dialogRef.current.addEventListener('cancel', (e) => {
				e.preventDefault()
				if (!preventClose) {
					close()
				}
			})
		}

		return () => {
			if (dialogRef.current) {
				dialogRef.current.removeEventListener('click', () => {})
				dialogRef.current.removeEventListener('cancel', () => {})
			}
		}
	}, [dialogRef.current])

	return !!initializedEvents
}
