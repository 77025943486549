import { MODAL_TYPE } from '../types'

export const CLOSING_ANIMATION_DURATION = 500
export const OPENING_ANIMATION_DURATION = 300
const ANIMATED_TYPES = [
	MODAL_TYPE.SHEET,
	MODAL_TYPE.SHEET_FULL_SCREEN,
	MODAL_TYPE.SHEET_INSPIRATIONAL,
	MODAL_TYPE.SHEET_SMALL,
	MODAL_TYPE.SIDE,
	MODAL_TYPE.SIDE_FULL_CONTENT,
	MODAL_TYPE.FULL_SCREEN,
]

export function isAnimatedType(type: MODAL_TYPE): boolean {
	return ANIMATED_TYPES.includes(type)
}
