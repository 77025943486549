import { LayoutStateLabelsContext } from 'labels/context/LayoutLabelsProvider/LayoutLabelsProvider'
import { useContext } from 'react'

/** @throws {Error} If useLabels is used without LayoutLabelsProvider */
export const useLabels = () => {
	const labels = useContext(LayoutStateLabelsContext)
	if (!labels) {
		throw new Error(
			'useLabels must be used withing a LayoutLabelsProvider and initLabels must be called before useLabels'
		)
	}
	return labels
}
