/**
 * @fileoverview
 * This file contains functions to prevent scrolling of the body and html elements
 * when the modal is open and restore it when the modal is closed.
 * @see https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
 */

let savedScrollPosition: number | null = null

export const hideOverflows = () => {
	if (document) {
		savedScrollPosition = window.scrollY

		const { body } = document
		body.style.top = `-${savedScrollPosition}px`
		body.style.position = 'fixed'
		body.style.scrollbarGutter = 'stable both-edges'

		const header = document.getElementById('header')
		if (header?.style.position === 'sticky') {
			header.style.position = 'fixed'
		}
	}
}

export const restoreOverflows = () => {
	if (document) {
		const { body } = document
		body.removeAttribute('style')

		const header = document.getElementById('header')
		if (header?.style.position === 'fixed') {
			header.removeAttribute('style')
		}
	}

	if (savedScrollPosition) {
		window.scrollTo(0, savedScrollPosition)
		savedScrollPosition = null
	}
}
