import { applyStylesIf, cx } from 'utils/cx'

import { CloseModal } from '../../CloseModal/CloseModal'
import { HeadersTitles } from './HeaderTitles/HeaderTitles'

import styles from '../ModalHeader.module.scss'

export function CompleteHeader({
	title,
	close,
	subtitle,
	showBar,
	showHeaderSeparator,
	showTitle,
	hasSheetTitle,
	showClose,
	preventClose,
	hasCenteredTitle,
	className,
}: {
	title: string
	showBar: boolean
	showHeaderSeparator: boolean
	showTitle: boolean
	hasSheetTitle: boolean
	showClose: boolean
	preventClose: boolean
	hasCenteredTitle: boolean
	subtitle?: string
	close: () => void
	className?: string
}) {
	return (
		<header
			className={cx(
				className,
				styles.header,
				applyStylesIf(showHeaderSeparator && showTitle, styles.withSeparator),
				applyStylesIf(showTitle, styles.withTitle)
			)}
		>
			{showBar && (
				<CloseModal preventClose={preventClose} close={close} asBar />
			)}
			<div className={styles.positionAnchor}>
				<HeadersTitles
					title={title}
					subtitle={subtitle}
					hasSheetTitle={hasSheetTitle}
					hasCenteredTitle={hasCenteredTitle}
					showTitle={showTitle}
				/>
				{showClose && (
					<CloseModal
						preventClose={preventClose}
						close={close}
						margin={hasCenteredTitle}
					/>
				)}
			</div>
		</header>
	)
}
