import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useLayoutEffect, useState } from 'react'

import mediaQ from 'responsive/utils/mediaQueries.module.scss'

const CHANGE_EVENT_NAME = 'change'
export type MatchMediaQueries = string[]

/* eslint react-hooks/exhaustive-deps: warn */

export interface ResponsiveType {
	availableScreenSize: boolean
	isTouchable: boolean
	/** great! are you using isSmall on client */
	isSmall: boolean
	isMedium: boolean
	isLarge: boolean
	isLessThanLarge: boolean
	isLargeOrGreater: boolean
	isLessThanExtraLarge: boolean
	isExtraLargeOrGreater: boolean
}

export const responsiveQueries = [
	mediaQ.issmall,
	mediaQ.ismedium,
	mediaQ.islarge,
	mediaQ.isextralarge,
]

/**
 *
 * @returns {ResponsiveDeviceInfo}/
 */
export function useResponsiveRaw(requestedFromSmallDevice = false) {
	/**
	 * To prevent the flickering of the screen when hydrating the page,
	 * we need to set the initial state to the most probable value.
	 *
	 * Most probably is you have a page request from a small device:
	 * - isTouchable: most probably you are using a touchable screen
	 * - isSmall: you are requesting from a mobile device
	 * - isMedium: you are requesting from a tablet device
	 * - isLessThanLarge: you are requesting from a tablet or mobile
	 * - isLessThanExtraLarge: you are requesting from a tablet or mobile
	 */
	const [responsiveInfo, setResponsiveInfo] = useState<ResponsiveType>({
		availableScreenSize: false,
		isTouchable: requestedFromSmallDevice,
		isSmall: requestedFromSmallDevice,
		isMedium: requestedFromSmallDevice,
		isLarge: !requestedFromSmallDevice,
		isLessThanLarge: requestedFromSmallDevice,
		isLargeOrGreater: !requestedFromSmallDevice,
		isLessThanExtraLarge: requestedFromSmallDevice,
		isExtraLargeOrGreater: !requestedFromSmallDevice,
	})

	useLayoutEffect(() => {
		const mediaQueries = responsiveQueries.flatMap(
			(q) => window.matchMedia(q) || []
		)
		const getMatches = (): ResponsiveType => {
			const [isSmall, isMedium, isLarge, isExtraLarge] =
				mediaQueries.map((mq) => mq.matches) || []
			return {
				isSmall,
				isMedium,
				isLarge,
				availableScreenSize: true,
				isTouchable: !!window.matchMedia('(pointer: coarse)')?.matches,
				isLessThanLarge: isSmall || isMedium,
				isLessThanExtraLarge: !isExtraLarge,
				isLargeOrGreater: isLarge || isExtraLarge,
				isExtraLargeOrGreater: isExtraLarge,
			}
		}

		setResponsiveInfo(getMatches)

		function changeHandler() {
			setResponsiveInfo(getMatches)
		}

		mediaQueries.forEach((mq) => {
			mq.addEventListener(CHANGE_EVENT_NAME, changeHandler)
		})

		return () => {
			mediaQueries.forEach((mq) => {
				mq.removeEventListener(CHANGE_EVENT_NAME, changeHandler)
			})
		}
	}, [])

	return responsiveInfo
}

export function useResponsive() {
	const { isMobile } = useMasterData()
	return useResponsiveRaw(isMobile)
}
